import React, { useRef, useState, useEffect } from 'react';
import MainV3Hero from './MainV3Hero';
import NavBarV2 from '../../component/NavBarV2';
import ToTopButton from '../../component/ToTopButton';

const MainV3Page = () => {
  const videoRef = useRef(null);
  const [hasEndedOnce, setHasEndedOnce] = useState(true);
  const [isPageReady, setIsPageReady] = useState(false);

  // Pause/Resume Media Based on Tab Visibility
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Pause video if tab is not active
        if (videoRef.current && !videoRef.current.paused) {
          videoRef.current.pause();
        }
      } else {
        // Resume video if tab is active
        if (videoRef.current && videoRef.current.paused) {
          videoRef.current.play();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <div className="bg-black">
      {/* Button Section */}
      {!isPageReady && (
        <div className="fixed bg-black w-full h-full flex flex-col items-center justify-center overflow-y-hidden transition-all duration-700 opacity-100">
          <button
            className="bg-gradient-to-r from-purple-500 to-blue-500 text-white text-md lg:text-lg font-semibold py-3 px-6 lg:py-4 lg:px-8 rounded-xl hover:scale-110 transition-all duration-300"
            onClick={() => setIsPageReady(true)}
          >
            Experience Now
          </button>
        </div>
      )}

      {/* Main Content Section */}
      {isPageReady && (
        <div className="relative bg-black flex flex-col items-center justify-center overflow-x-hidden transition-all duration-700 opacity-100">
          <NavBarV2 landingVideoEnded={hasEndedOnce} isSticky />
          <MainV3Hero
            videoRef={videoRef}
            hasEndedOnce={hasEndedOnce}
            setHasEndedOnce={setHasEndedOnce}
          />
          <ToTopButton />
        </div>
      )}
    </div>
  );
};

export default MainV3Page;
