// ComicType.js
import React, { useState } from "react";

const ComicType = ({
  category,
  index,
  isChoosen,
  setActiveSlide,
  setShowComicContent,
  navigate
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const getImageSrc = () => {
    if (isChoosen) return `/assets/story/${category}_choosen.png`;
    if (isHovered) return `/assets/story/${category}_hover.png`;
    return `/assets/story/${category}_normal.png`;
  };

  // Main image click to set active slide
  const handleSlideClick = () => {
    setActiveSlide(index);
  };

  // Button click to trigger actions without changing active slide
  const handleButtonClick = (e) => {
    e.stopPropagation(); // Prevent main click event

    if (category === "funs") {
      setShowComicContent(true);
    } else if (category === "memes") {
      navigate("/story/high-fidelity-hero?type=1");
    } else if (category === "stories") {
      navigate("/story/high-fidelity-hero?type=2");
    }
  };

  return (
    <div
      onClick={handleSlideClick} // Only updates active slide
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="relative"
    >
      <img
        src={getImageSrc()}
        alt={`${category} option ${index}`}
        className="w-[35vh] lg:w-80 h-auto object-cover rounded-2xl transition-all duration-300"
      />

      {/* Show the button only if this image is the chosen one */}
      {isChoosen && (
        <a
          onClick={handleButtonClick} // Trigger button-specific actions
          href="#"
          className="absolute bottom-12 right-0 transform -translate-x-1/2 w-[5rem] scale-105 hover:scale-125 transition-all duration-300"
          draggable="false"
        >
          <img
            className="w-full rotate-infinite"
            src="/assets/mainV2/hero-button-cover.webp"
            alt="button cover"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <img
              className="w-[65%]"
              src="/assets/mainV2/hero-button-arrow.webp"
              alt="button arrow"
            />
          </div>
        </a>
      )}
    </div>
  );
};

export default ComicType;
