import React from 'react';
import StoryHero from './StoryHero';
import Navbar from '../../component/NavBar';
import Footer from '../../component/Footer';

const StoryPage = () => {
  return (
    <div className="relative flex flex-col overflow-x-hidden items-center justify-center overflow-hidden bg-black z-0">
      <Navbar className="absolute z-[1002]" isSticky/>
      <StoryHero />
      <Footer />
    </div>
  );
};

export default StoryPage;
