import React, { useState, useEffect } from "react";
import ChevronUp from "../../component/icons/ChevronUp";
import ChevronDown from "../../component/icons/ChevronDown";
import CrossIcon from "../../component/icons/CrossIcon";
import ChevronLeftStyled from "../../component/icons/ChevronLeftStyled";
import ChevronRightStyled from "../../component/icons/ChevronRightStyled";
import { useAppDispatch } from "../../hooks/storeHooks";
import {
  getComicList,
  useComicList,
  resetComicList,
} from "../../sagaStore/slices/comicSlice";
import { PropagateLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

const ComicContent = ({ type, closeComicContent }) => {
  const { t, i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("");
  const [currentEpisodeIndex, setCurrentEpisodeIndex] = useState(0);
  const dispatch = useAppDispatch();
  const comicList = useComicList();
  const [loading, setLoading] = useState(true);

  // Sort the comic list in ascending order
  const sortedComicList = comicList
    ? [...comicList].sort((a, b) => {
      const titleA = a.title["en"] || "";
      const titleB = b.title["en"] || "";
      const numA = parseInt(titleA.split(".")[0], 10); // Extract number from title
      const numB = parseInt(titleB.split(".")[0], 10);
      return numA - numB;
    })
    : [];

  useEffect(() => {
    setLoading(true);

    const timer = setTimeout(() => {
      dispatch(getComicList(type));
    }, 1000);

    return () => {
      clearTimeout(timer);
      dispatch(resetComicList());
    };
  }, [dispatch, type]);

  useEffect(() => {
    if (comicList && comicList.length > 0) {
      setLoading(false);
      setSelectedOption(comicList[0]?.title["en"] || "");
    }
  }, [comicList]);

  const handlePreviousEpisode = () => {
    if (currentEpisodeIndex > 0) {
      const newIndex = currentEpisodeIndex - 1;
      setCurrentEpisodeIndex(newIndex);
      setSelectedOption(sortedComicList[newIndex]?.title["en"] || "");
    }
  };

  const handleNextEpisode = () => {
    if (currentEpisodeIndex < comicList.length - 1) {
      const newIndex = currentEpisodeIndex + 1;
      setCurrentEpisodeIndex(newIndex);
      setSelectedOption(sortedComicList[newIndex]?.title["en"] || "");
    }
  };

  const handleDropdownSelect = (option, index) => {
    setSelectedOption(option);
    setCurrentEpisodeIndex(index);
  };

  const CustomDropdown = ({ options, value, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option, index) => {
      onSelect(option, index);
      setIsOpen(false);
    };

    return (
      <div className="relative text-white font-bold font-outfit py-4 flex justify-center">
        <div
          onClick={handleToggleDropdown}
          className="py-2 bg-transparent rounded-full font-semibold flex items-center"
        >
          <span className="text-xs lg:text-sm xl:text-md">{value} &nbsp;</span>
          {isOpen ? (
            <ChevronUp fill="#FFFFFF" width="12" height="12" />
          ) : (
            <ChevronDown fill="#FFFFFF" width="12" height="12" />
          )}
        </div>
        {isOpen && (
          <div
            className="absolute mt-8 w-full bg-black bg-opacity-50 rounded shadow-lg z-10"
            style={{ maxHeight: "200px" }} // Fixed height
          >
            <div
              className="overflow-y-auto max-h-[200px] p-2 scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-transparent"
            >
              {options.map((option, index) => (
                <div
                  key={index}
                  onClick={() => handleOptionClick(option, index)}
                  className="px-2 lg:px-3 py-1 lg:py-2 hover:bg-gray-700 text-xs lg:text-sm xl:text-md cursor-pointer"
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };


  const renderImage = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center h-[50dvh]">
          <PropagateLoader color={"#FFB23F"} />
        </div>
      );
    }

    if (sortedComicList && sortedComicList.length > 0) {
      return (
        <img
          src={sortedComicList[currentEpisodeIndex]?.content[0]} // Use sortedComicList
          alt={sortedComicList[currentEpisodeIndex]?.title["en"] || ""} // Use title for alt
          className="w-[100vh] h-full object-contain"
          draggable="false"
        />
      );
    } else {
      return <div>{t("comic missing")}</div>;
    }
  };

  return (
    <div className="relative h-full w-full py-8 lg:py-0 mt-2 lg:mt-4 xl:absolute xl:inset-0 bg-gradient-to-b from-[rgba(0,0,0,0.5)] to-[rgba(0,0,0,0.8)] flex flex-col justify-center items-center backdrop-blur-lg overflow-hidden">
      {/* Start and End Fade Out Overlays */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="h-full w-full flex items-center justify-center">
        <img
          src="/assets/story/social-pop-up-bg.webp"
          alt="background"
          className="h-[90vh] lg:h-[80vh] w-[90vw] lg:w-[80vw]"
          draggable="false"
        />
      </div>

      <div className="hidden lg:block absolute top-[9rem] left-[1.5rem] lg:top-[3rem] lg:left-[7rem]">
        <img
          src="/assets/story/story-tape.webp"
          alt="story tape"
          className="w-[12dvw] h-auto"
          draggable="false"
        />
      </div>

      <div className="hidden lg:block absolute bottom-[9.5rem] right-[1.5rem] lg:bottom-[3rem] lg:right-[7rem]">
        <img
          src="/assets/story/story-tape.webp"
          alt="story tape"
          className="w-[12dvw] h-auto"
          draggable="false"
        />
      </div>

      <div className="absolute m-auto h-[80vh] w-[80vw]">
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="w-full flex justify-end pr-[1rem] lg:pr-[3rem] z-10">
            <div
              className="my-[1.5rem] lg:m-[3rem] mb-4 lg:mb-0 hover:opacity-50 transition-all duration-300"
              onClick={closeComicContent}
            >
              <CrossIcon fill="#C5C5C5" width="14" height="14" />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row w-full justify-between items-center px-[1rem] xl:px-[10rem] pb-[1rem] lg:pb-[2rem]">
            <div className="flex flex-row items-center">
              <h1 className="text-[#FFC85A] text-[28px] lg:text-3xl xl:text-4xl text-center lg:text-justify font-normal font-bigNoodle uppercase leading-snug">
                {selectedOption} &nbsp;
              </h1>
            </div>

            <div className="relative ml-4">
              <CustomDropdown
                options={sortedComicList.map(
                  (comic, index) =>
                    comic.title["en"] || ""
                )}
                value={selectedOption}
                onSelect={handleDropdownSelect}
              />
            </div>
          </div>

          <div className="flex flex-col w-auto h-auto items-center overflow-hidden lg:hidden">
            <div className="w-[70dvw] h-[50dvh] flex flex-col justify-center items-center mb-4">
              {renderImage()}
            </div>
            <div className="flex flex-row justify-center gap-[2rem]">
              <div
                className={`z-10 ${currentEpisodeIndex === 0 ? "hidden" : ""
                  }`}
                onClick={handlePreviousEpisode}
              >
                <ChevronLeftStyled fill="#ffffff" width="35" height="35" />
              </div>
              <div
                className={`z-10 ${currentEpisodeIndex === comicList.length - 1 ? "hidden" : ""
                  }`}
                onClick={handleNextEpisode}
              >
                <ChevronRightStyled fill="#ffffff" width="35" height="35" />
              </div>
            </div>
          </div>


          <div className="lg:flex justify-center items-center w-full h-full rounded-3xl overflow-hidden hidden">
            <div className="flex justify-center items-center">
              <div
                className={`z-10 mr-auto ${currentEpisodeIndex === 0 ? "opacity-0" : "opacity-100 "
                  }`}
                onClick={currentEpisodeIndex === 0 ? () => { } : handlePreviousEpisode}
              >
                <ChevronLeftStyled fill="#ffffff" width="48" height="48" />
              </div>
              <div className="w-[45dvw] h-[40dvh] xl:h-[50dvh] flex justify-center items-center mx-[4rem]">
                {renderImage()}
              </div>
              <div
                className={`z-10 ml-auto ${currentEpisodeIndex === comicList.length - 1 ? "opacity-0" : "opacity-100 "
                  }`}
                onClick={currentEpisodeIndex === comicList.length - 1 ? () => { } : handleNextEpisode}
              >
                <ChevronRightStyled fill="#ffffff" width="48" height="48" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ComicContent;
