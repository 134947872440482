import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const lngs = {
  en: { nativeName: "English" },
  cn: { nativeName: "中文" },
};

const Navbar = ({ isSticky, landingVideoEnded }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const location = useLocation();
  const navBarRef = useRef();
  const [isScrolling, setIsScrolling] = useState(false);

  const normalizedPath = location.pathname.replace(/\/+$/, "");

  useEffect(() => {
    if(landingVideoEnded === undefined || landingVideoEnded === null || landingVideoEnded || isScrolling){
      makeVisible();
    }
  },[landingVideoEnded, isScrolling]);

  useEffect(() => {
    const handleScroll = () => {
      if (!isScrolling) {
        setIsScrolling(true);
      }

      // Optional: reset isScrolling after a short delay
      const timeout = setTimeout(() => {
        setIsScrolling(false);
      }, 100); // Adjust delay as needed

      return () => clearTimeout(timeout); // Clean up the timeout on unmount or re-scroll
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolling]);

  const makeVisible = () => {
    if (navBarRef.current) {
      // Remove opacity-0 and add opacity-100
      navBarRef.current.classList.remove('opacity-0');
      navBarRef.current.classList.add('opacity-100');
    }
  };

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <nav
      ref={navBarRef}
      className={`fixed w-full flex justify-center z-[9999] top-0 opacity-0 transition-opacity duration-300 bg-gradient-to-b from-[#1c1c1c]`}
    >
      <div className="w-full max-w-[1920px] px-[2rem] xl:px-[8rem] py-[2rem] items-center mx-auto flex justify-between lg:justify-center">
        
        {/* logo */}
        <div className="lg:w-[10%] z-[9999]">
          <Link to="https://www.animara.world/">
            {/* mobile logo */}
            {isOpen?
              <img
                src="/assets/icons/animara-logo-white.webp"
                className="block lg:hidden w-24"
                alt="animara icon"
              />
              :
              <img
                src="/assets/icons/animara-logo-white-mobile.webp"
                className="block lg:hidden w-8"
                alt="animara icon"
              />
            }
              
            <img
              src="/assets/icons/animara-logo-white.webp"
              className="hidden lg:block w-[10dvw]"
              alt="animara icon"
            />
          </Link>
        </div>

        {/* hamburger menu */}
        {/* <button className="block lg:hidden z-[9999]" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <svg className="h-8 w-8" viewBox="0 0 24 24" fill="none">
              <path
                d="M6 18L18 6M6 6l12 12"
                stroke="#FFFFFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          ) : (
            <svg className="h-8 w-8" viewBox="0 0 24 24" fill="none">
              <path
                d="M4 6H20M4 12H20M4 18H20"
                stroke="#FFFFFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          )}
        </button> */}

        <div className="lg:w-[90%] hidden lg:flex justify-end">
          <nav className={`bg-transparent text-[#FFFFFF]`}>
            <ul className="flex space-x-[1rem] xl:space-x-[2.5rem] items-center text-base xl:text-lg font-semibold font-outfit whitespace-nowrap">
            </ul>
          </nav>
        </div>

        {isOpen && (
          <div className="absolute top-0 left-0 w-full h-[100vh] z-[1000]">
            <div className="absolute inset-0 pointer-events-none z-[10]">
              <img
                className="w-full h-full object-cover object-bottom"
                src="/assets/mainV2/mobile-menu-bg.webp"
                alt="Event Background"
              />
            </div>
            <nav className="relative text-[#FFFFFF] z-[150] mt-[6rem]">
              <ul className="flex flex-col px-[2rem] items-start space-y-4 py-4 text-4xl font-normal font-bigNoodle uppercase">
                <li
                  className={`text-[#FFC85A] ${
                    normalizedPath === "/login" ? "text-[#FFC85A]" : ""
                  }`}
                >
                  <Link to="https://app.animara.world/login">{t("login")}</Link>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
