import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const MainV2Hero = ({ videoRef, hasEndedOnce, setHasEndedOnce }) => {
  const { t } = useTranslation();
  const heroInfoRef = useRef(null);

  const makeVisible = () => {
    if (heroInfoRef.current) {
      // Remove opacity-0 and add opacity-100
      heroInfoRef.current.classList.remove('opacity-0');
      heroInfoRef.current.classList.add('opacity-100');
    }
  };

  const handleVideoEnd = () => {
    if (!hasEndedOnce) {
      makeVisible();
      setHasEndedOnce(true);  // Mark that the video has ended once
    }
  };

  const isVideoLoaded = useCallback(() => {
    videoRef.current.play();
  }, [videoRef]);

  useEffect(() => {
    videoRef.current.play();
  }, [videoRef]);

  return (
    <div className="relative w-auto lg:w-screen max-width-none lg:max-w-[1920px] h-screen lg:h-screen  overflow-hidden flex flex-col justify-end items-center bg-mainv2-hero-gradient">

      {/* Start and End Fade Out Overlays */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="absolute h-[650px] lg:h-full inset-0">
        <video
          className="w-full h-full object-cover lg:object-center lg:static relative lg:right-0 -right-2"
          playsInline
          preload="auto"
          loop
          onCanPlay={() => {
            videoRef.current.pause();
            isVideoLoaded();
          }}
          onEnded={handleVideoEnd}
          onError={(err) => {
            console.log(err);
          }}
          ref={videoRef}
        >
          <source src="https://storage.animara.world/HOME_Video_V2.mp4" type="video/mp4" />
        </video>

        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent z-10 pointer-events-none"></div>
      </div>

      <div ref={heroInfoRef} className="flex flex-col justify-end items-center w-[90%] lg:w-[80%] pb-[4rem] lg:pb-[8rem] transition-opacity gap-[0.25rem] duration-300 z-30">
        <h1
          className="text-[#FFFFFF] text-5xl lg:text-7xl font-bold font-bigNoodle uppercase text-center mb-4"
          style={{ textShadow: '3px 3px 6px rgba(0, 0, 0, 0.5)' }}
        >
          The Realm of Animara
        </h1>
        <p className="text-[#FFFFFF] text-md lg:text-lg font-medium text-center mb-8">
        Embrace the future and explore Memeverse, the groundbreaking ecosystem bridging virtual and real-world experiences!
        </p>
        <a
          type="button"
          href="https://app.animara.world/login"
          className="bg-gradient-to-r from-purple-500 to-blue-500 text-white text-md lg:text-lg font-semibold py-3 px-6 lg:py-4 lg:px-8 rounded-xl hover:scale-110 transition-all duration-300"
        >
          Play Now
        </a>

      </div>
    </div>
  );
};

export default MainV2Hero;
