import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/storeHooks";
import {
  getComicList,
  useComicList,
  resetComicList,
} from "../../sagaStore/slices/comicSlice";
import ChevronLeft from "../../component/icons/ChevronLeft";
import ComicCard from "../../component/comic/ComicCard";
import { PropagateLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

const HighFidelityComicHero = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const comicList = useComicList();
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    setLoading(true); 

    const timer = setTimeout(() => {
      dispatch(getComicList(type));
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
      dispatch(resetComicList());
    };
  }, [dispatch, type]);

  const handleBack = () => {
    navigate(-1); // Go back one step in history
  };

  const getTitle = (type) => {
    switch (type) {
      case "1":
        return t("memes");
      case "2":
        return t("stories");
      default:
        return "Comic Series"; // Default title
    }
  };

  return (
    <div className="relative pb-[25rem] lg:pb-0 h-auto min-h-[100vh] w-screen max-w-[1920px] overflow-hidden justify-center p-2">
      
      {/* Start and End Fade Out Overlays */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="absolute inset-0 overflow-hidden">
        <img
          className="w-screen h-full object-cover"
          src="/assets/story/animara-story-bg.webp"
          alt="Background"
        />
      </div>

      <div className="lg:hidden absolute inset-0 z-[-1]">
        <img
          className="min-h-screen object-cover"
          src="/assets/story/animara-story-mobile-bg.webp"
          alt="Background"
          style={{
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        />
      </div>

      <div className="flex flex-col mx-[2rem] lg:mx-[5rem] xl:mx-[15rem] mt-[6rem] lg:mt-[7rem]">
        <div className="flex flex-col animate-fadeIn">
          <div
            onClick={handleBack}
            className="text-white font-semibold font-outfit  flex items-center mb-[1rem]"
          >
            <ChevronLeft fill="#80E8FF" width="15" height="15" />
            <span className="text-[#80E8FF] ml-2">{t("back")}</span>
          </div>

          <h1 className="text-4xl lg:text-5xl xl:text-6xl text-[#FFC85A] font-normal font-bigNoodle uppercase mb-[1rem] lg:mb-[2rem]">
            {getTitle(type)}
          </h1>

          {loading ? (
            <div className="flex justify-center items-center h-[40dvh]">
              <div className="flex justify-center items-center h-16">
                <PropagateLoader color={"#FFB23F"} />
              </div>
            </div>
          ) : (
            <div className="w-[100%] lg:w-full h-auto lg:h-[30dvh] xl:h-[40dvh] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[2rem]">
              {comicList &&
                comicList.length > 0 &&
                comicList.map((comic, index) => (
                  <ComicCard comic={comic} index={index} />
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HighFidelityComicHero;
