// StoryHero.js
import React, { useState, useEffect } from "react";
import ComicType from "../../component/comic/ComicType";
import ComicContent from "./ComicContent";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const StoryHero = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeSlide, setActiveSlide] = useState(0);
  const [showComicContent, setShowComicContent] = useState(false);

  const comicTypes = [
    { category: "funs", title: t("funs") },
    { category: "memes", title: t("memes") },
    { category: "stories", title: t("stories") }
  ];

  const closeComicContent = () => {
    setShowComicContent(false);
  };

  useEffect(() => {
    if (showComicContent) {
      document.body.style.overflow = "hidden"; // Prevent scrolling
    } else {
      document.body.style.overflow = ""; // Restore scrolling
    }

    // Clean up on unmount
    return () => {
      document.body.style.overflow = "";
    };
  }, [showComicContent]);

  // Touch state to track gesture
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  // Handle touch start
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  // Handle touch move
  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  // Handle touch end (detect swipe direction)
  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const touchDiff = touchStart - touchEnd;

    if (touchDiff > 50) {
      // Swipe left
      setActiveSlide((prev) => (prev + 1) % comicTypes.length);
    } else if (touchDiff < -50) {
      // Swipe right
      setActiveSlide((prev) => (prev - 1 + comicTypes.length) % comicTypes.length);
    }

    // Reset touch state
    setTouchStart(0);
    setTouchEnd(0);
  };

  return (
    <>
      <div
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        className="max-w-[1920px] relative w-full h-[100vh] lg:w-screen flex flex-row lg:flex-col items-start lg:items-center justify-center mb-[-1rem] -gap-4"
      >
        <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
        <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

        <div className="absolute inset-0 overflow-hidden">
          <img
            className="w-screen h-full object-cover"
            src="/assets/story/animara-story-bg.webp"
            alt="Background"
          />
        </div>
        <div className="lg:hidden absolute inset-0 z-[-1]">
          <img
            className={`min-h-screen ${showComicContent ? "h-full" : ""} object-cover`}
            src="/assets/story/animara-story-mobile-bg.webp"
            alt="Background"
            style={{
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
            }}
          />
        </div>

        {/* Main content with ComicType components */}
        {comicTypes.map((comic, index) => (
          <div
            key={index}
            className={`absolute rounded-lg transition-transform duration-500 mt-[10vh] lg:mt-4 lg:mb-60
                ${index === activeSlide
                ? "transform translate-x-0 z-10 scale-125 lg:scale-125"
                : index === (activeSlide + 1) % comicTypes.length
                  ? "transform translate-x-[100%] scale-90 opacity-75 lg:translate-x-[100%] lg:scale-90"
                  : "transform -translate-x-[100%] scale-90 opacity-75 lg:-translate-x-[100%] lg:scale-90"
              }`}
          >
            <ComicType
              category={comic.category}
              title={comic.title}
              index={index}
              isChoosen={activeSlide === index}
              setActiveSlide={setActiveSlide}
              setShowComicContent={setShowComicContent}
              navigate={navigate}
            />
          </div>
        ))}
      </div>

      {/* Conditional rendering of ComicContent popup */}
      {showComicContent && (
        <div className="fixed inset-0 bg-white bg-opacity-20 flex justify-center items-center z-[1001]">
          <ComicContent type={0} closeComicContent={closeComicContent} />
        </div>
      )}

      <div className="absolute flex flex-col items-center justify-content-center space-y-4 mt-[8vh] lg:mt-52">
        {/* Navigation dots */}
        <div className="flex space-x-4">
          {comicTypes.map((_, index) => (
            <button
              key={index}
              onClick={() => setActiveSlide(index)}
              className={`w-1 h-1 lg:w-2 lg:h-2 rounded-full transition-colors ${
                activeSlide === index ? "bg-yellow-500" : "bg-gray-400"
              }`}
            />
          ))}
        </div>

        {/* Title and description */}
        <div className="text-center mt-8">
          <h1 className="text-4xl lg:text-6xl text-[#FFC85A] font-normal font-bigNoodle tracking-wider uppercase mb-4">
            {t("animics title")}
          </h1>
          <p className="text-white text-sm lg:text-base font-medium font-outfit w-[40vh] lg:w-[70vh]">
            {t("animics description")}
          </p>
        </div>
      </div>
    </>
  );
};

export default StoryHero;
